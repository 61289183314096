
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonSearchbar,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonIcon,
  IonButtons,
  IonFab,
  IonFabButton,
} from "@ionic/vue";

import { refresh } from "ionicons/icons";

import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import apiClienti from "../services/clienti";

import { openToast } from "../services/toast";

import { getCustomers } from "../services/localbase";
import { log } from "console";

export default {
  name: "Tab1",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonSearchbar,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonIcon,
    IonButtons,
    IonFab,
    IonFabButton,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const clienti = ref([]);
    const showClienti = ref([]);
    const searchedClienti = ref([]);
    const searchTerm = ref("");

    const customersToSearch = ref(true); //flag to prevent load more customers when search is active

    function pushData() {
      const max = showClienti.value.length + 50;
      const min = max - 50;
      //console.log(min, max);
      for (let i = min; i < max; i++) {
        //console.log(clienti.value[i]);
        showClienti.value.push(clienti.value[i]);
      }
    }

    /* Get clienti */
    async function getClienti() {
      loading.value = true;
      //const localClienti = JSON.parse(localStorage.getItem("clienti"));

      const customersDB = await getCustomers();
      if (customersDB.length != 0) {
        //console.log("clienti da locale");
        clienti.value = customersDB;
        searchedClienti.value = customersDB;
        pushData();
        loading.value = false;
      } else {
        //console.log("clienti da api");
        apiClienti
          .getClienti()
          .then((response) => {
            //console.log(response);
            clienti.value = response;
            searchedClienti.value = response;
          })
          .then(() => {
            pushData();
          })
          .catch((error) => {
            //alert(error);
            error.value = true;
            openToast("Errore durante la richiesta dei dati", "danger");
            console.error(error);
          })
          .finally(() => {
            loading.value = false;
            //console.log("fine");
          });
      }

      /*if (localClienti) {
        console.log("clienti from localstorage");
        clienti.value = localClienti;
        searchedClienti.value = localClienti;
        pushData();
        loading.value = false;
      } else {
        apiClienti
          .getClienti()
          .then((response) => {
            //console.log(response);
            clienti.value = response;
            searchedClienti.value = response;
          })
          .then(() => {
            pushData();
          })
          .catch((error) => {
            //alert(error);
            error.value = true;
            openToast("Errore durante la richiesta dei dati", "danger");
            console.error(error);
          })
          .finally(() => {
            loading.value = false;
            //console.log("fine");
          });
      }*/
    }

    getClienti();

    //passing id to retrive detail in ClienteDetal page
    function openDetail(id: string) {
      //console.log(id);
      router.push(`tab1/${id}`);
    }

    //Print users company or name and last name based on condition
    // function riferimentoCliente(cliente) {
    //   if (
    //     cliente.customers_name != null &&
    //     cliente.customers_last_name != null
    //   ) {
    //     return `${cliente.customers_name} ${cliente.customers_last_name}`;
    //   } else {
    //     return `${cliente.customers_company}`;
    //   }
    // }
    function riferimentoCliente(cliente) {
      if (cliente.customers_company) {
        return `${cliente.customers_company}`;
      } else if (cliente.customers_name && cliente.customers_last_name) {
        return `${cliente.customers_name} ${cliente.customers_last_name}`;
      } else if (cliente.customers_name && !cliente.customers_last_name) {
        return `${cliente.customers_name}`;
      } else if (!cliente.customers_name && !cliente.customers_last_name) {
        return `${cliente.customers_last_name}`;
      }
    }

    //Filter customers based on user search
    function filterItems(term: string) {
      customersToSearch.value = false;
      //console.log(term);
      const splitTerm = term.split(" ");
      term = term.split(" ").join("");
      showClienti.value = searchedClienti.value.filter(function (cliente) {
        const company = cliente.customers_company;
        const name = cliente.customers_name;
        const last_name = cliente.customers_last_name;
        if (company != null) {
          // console.log(splitTerm);
          const companyToSearch = company.replace(/ /g, "");
          //console.log(companyToSearch);
          // console.log("term: ", term);
          // console.log(companyToSearch.toLowerCase());
          // return companyToSearch.toLowerCase().includes(term.toLowerCase());

          // const length = false;
          // splitTerm.forEach((element) => {
          //   if (companyToSearch.toLowerCase().includes(element.toLowerCase())) {
          //   }
          // });

          if (splitTerm.length == 1) {
            return companyToSearch.toLowerCase().includes(term.toLowerCase());
          }

          let flag = false;
          //console.log(splitTerm.length);
          for (let index = 0; index < splitTerm.length; index++) {
            if (index < splitTerm.length - 1) {
              const indice = index + 1;
              //console.log(indice);
              const element = splitTerm[index];
              const succ = splitTerm[indice];
              // console.log(
              //   "el: ",
              //   element,
              //   "succ: ",
              //   succ,
              //   "company:  ",
              //   companyToSearch
              // );
              flag =
                companyToSearch.toLowerCase().includes(element.toLowerCase()) &&
                companyToSearch.toLowerCase().includes(succ.toLowerCase());
            }
          }
          return flag;
          // return companyToSearch.toLowerCase().includes(term.toLowerCase());
        } else {
          const tmpNameLastName = (name + last_name).replace(/ /g, "");
          const tmpLastNameName = (last_name + name).replace(/ /g, "");
          return (
            name.toLowerCase().includes(term.toLowerCase()) ||
            last_name.toLowerCase().includes(term.toLowerCase()) ||
            tmpNameLastName.toLowerCase().includes(term.toLowerCase()) ||
            tmpLastNameName.toLowerCase().includes(term.toLowerCase())
          );
        }

        /*if (cliente.customers_company != null) {
          return cliente.customers_company
            .toLowerCase()
            .includes(term.toLowerCase());
        } else {
          return (
            cliente.customers_name.toLowerCase().includes(term.toLowerCase()) ||
            cliente.customers_last_name
              .toLowerCase()
              .includes(term.toLowerCase())
          );
        }*/
      });
      //console.log(showClienti.value.length);
      if (term.length < 1) {
        customersToSearch.value = true;
        //svuoto i clienti da visualizzare e li inizializzo di nuovo con pushdata
        showClienti.value = [];
        pushData();
      }
    }

    //Refresh customers list
    const refreshCustomer = () => {
      loading.value = true;
      apiClienti
        .getClienti()
        .then((response) => {
          clienti.value = response;
          searchedClienti.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei dati", "danger");
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
          //console.log("fine");
        });
    };

    /* Setting ionInifinite to load 50 customers at a */
    //const showClienti = ref([]);

    /*const pushData = () => {
      const max = showClienti.value.length + 50;
      const min = max - 50;
      console.log(min, max);
      for (let i = min; i < max; i++) {
        console.log(clienti.value[i]);
        showClienti.value.push(clienti.value[i]);
      }
    };*/

    function loadData(ev: any) {
      if (customersToSearch.value) {
        setTimeout(() => {
          pushData();
          ev.target.complete();
          // App logic to determine if all data is loaded
          // and disable the infinite scroll
          if (showClienti.value.length == clienti.value.length) {
            ev.target.disabled = true;
          }
        }, 500);
      }
    }

    /*const loadData = (ev: any) => {
      setTimeout(() => {
        pushData();
        console.log(showClienti);
        console.log("Loaded data");
        ev.target.complete();

        // App logic to determine if all data is loaded
        // and disable the infinite scroll
        if (showClienti.value.length == clienti.value.length) {
          ev.target.disabled = true;
        }
      }, 500);
    };*/

    //pushData();

    onMounted(() => {
      //pushData();
      /*apiClienti
        .getClienti()
        .then((response) => {
          console.log(response);
          clienti.value = response;
          searchedClienti.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei dati", "danger");
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
          console.log("fine");
        });*/
    });

    return {
      loading,
      refreshCustomer,
      filterItems,
      clienti,
      riferimentoCliente,
      openDetail,
      searchTerm,
      //filterList,
      showClienti,
      loadData,
      refresh,
      customersToSearch,
    };
  },
};
